export default function setupAxios(axios: any, tokenvalue: string) {
  axios.defaults.baseURL = "https://stagingapi.mtesthub.com";
  if (tokenvalue) {
    const token = JSON.parse(
      localStorage.getItem("userdetails") as string
    )?.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
  }
}
